import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed black',
    padding: '50px 0',
    cursor: 'pointer',
    marginTop: '2rem',
    marginBottom: '8px',

    '& .MuiTypography-root': {
      lineHeight: 'initial',
      fontSize: '14px',
      color: '#818181',
    },
  },
  label: {
    marginBottom: '-1rem',

    '& span': {
      color: theme.palette.primary.main,
    },
  },
  addCircleOutlineIcon: {
    fontSize: 40,
    marginBottom: '10px',
    color: '#081720',
  },
  root: {
    paddingTop: '2rem',
    backgroundColor: '#EFEDE9',
    position: 'relative',
    bottom: 0,
  },
  list: {
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
      '& a': {
        color: theme.palette.black.main,
      },
    },
  },
}));

export default useStyles;
