import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  buttonsContainer: {
    gap: '16px',

    '@media only screen and (max-width: 600px)': {
      flexDirection: 'column',

      '& button': {
        width: '100%',
      },
    },
  },
}));
