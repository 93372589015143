import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 52,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 600,
      padding: '52px 30px 30px',
    },
  },
  header: {
    marginBottom: 67,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '40px',
    color: theme.palette.black.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  mainSubHeading: {
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  subHeading: {
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.black.main,
  },
  price: {
    fontSize: '36px',
    lineHeight: '28px',
  },
  averageWaitTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h5': {
      whiteSpace: 'nowrap',
      marginLeft: '2rem',
    },
  },
  green: {
    color: theme.palette.primary.dark,
  },
  ukGreen: {
    color: theme.palette.primary.main,
  },
  disclaimer: {
    lineHeight: '28px',
  },
  button: {
    position: 'relative',
    display: 'flex',
    height: '50px',
    minWidth: '243px',
  },
  bookCallButton: {
    height: '46px',
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    alignSelf: 'center',
    border: `1px solid ${theme.palette.black.main}`,
    borderRadius: '3px',
  },
  backgroundBorder: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: '1px',
    top: '1px',
    background: theme.palette.black.main,
    borderRadius: '3px',
  },
}));

export default useStyles;
