import React from 'react';

import { Container, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from '../../../components/checkoutForm';
import { STRIPE_PUB_KEY } from '../../../environment';
import commonStyles from './styles';

const Payment: React.FC = () => {
  const commonClasses = commonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Payment
          </Typography>

          <Typography variant={matches ? 'h6' : 'h5'}>
            Contact Details
          </Typography>

          <Elements stripe={STRIPE_PUB_KEY ? loadStripe(STRIPE_PUB_KEY) : null}>
            <CheckoutForm />
          </Elements>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Payment;
