import create from 'zustand';

import { BookingFormPartial } from '../forms/booking/booking.types';
import { BookingQuote, QuoteConfiguration } from '../forms/quote/quote.types';
import { getStorageItem, setStorageItem } from '../utils/storage';

interface AppState {
  bookingQuote: BookingQuote | null;
  quoteConfiguration: QuoteConfiguration | null;
  setBookingQuote: (formData: BookingQuote | null) => void;
  setQuoteConfiguration: (data: QuoteConfiguration | null) => void;
  bookingFormData: BookingFormPartial | null;
  setBookingFormData: (formData: BookingFormPartial | null) => void;
}

const quoteFormFromStorage = getStorageItem<BookingQuote | null>(
  'bookingQuote',
  null,
);

const bookingFormFromStorage = getStorageItem<BookingFormPartial | null>(
  'bookingFormData',
  null,
);

export const useStore = create<AppState>((set) => ({
  bookingQuote: quoteFormFromStorage,
  bookingFormData: bookingFormFromStorage,
  quoteConfiguration: getStorageItem<QuoteConfiguration | null>(
    'quoteConfiguration',
    null,
  ),
  setBookingQuote: (formData: BookingQuote | null) => {
    setStorageItem('bookingQuote', formData);
    set({ bookingQuote: formData });
  },
  setBookingFormData: (formData) => {
    setStorageItem('bookingFormData', formData);
    set({ bookingFormData: formData });
  },
  setQuoteConfiguration: (data: QuoteConfiguration | null) => {
    setStorageItem('quoteConfiguration', data);
    set({ quoteConfiguration: data });
  },
}));
