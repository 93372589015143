import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 52,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 600,
    },
  },
  header: {
    marginBottom: 67,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  averageWaitTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h5': {
      whiteSpace: 'nowrap',
      marginLeft: '2rem',
    },
  },
  whiteButton: {
    justifyContent: 'flex-start',
    backgroundColor: '#ffffff',
  },
  //
  pageTitle: {
    fontWeight: 600,
  },
  pageSubTitle: {
    fontWeight: 600,
  },
  subTitleDetails: {
    fontSize: '12px',
    marginLeft: '16px',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: 4,
      marginLeft: 0,
    },
  },
  dropzoneWrapper: {
    paddingRight: '12px',

    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
    },
  },
  radioButtonsGrid: {
    '& .MuiFormControlLabel-root': {
      width: '40%',
    },
  },
  confirmContainer: {
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
    '& .MuiCheckbox-root': {
      paddingTop: '4px',
    },
  },
  tvSeriesParagraph: {
    marginBottom: '8px',
  },
  //
  tyHeader: {
    marginBottom: 67,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 70,
    },
  },
  tyTitle: {
    fontWeight: 600,
    marginBottom: '18px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 24,
    },
  },
  tySubTitle: {
    fontWeight: 600,
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
}));

export default useStyles;
