// APP
export const APP_ENV = process.env.GATSBY_ENV ?? 'development';
export const API_URL = process.env.GATSBY_API_URL;

// I18N
export const DEFAULT_LOCALE = process.env.GATSBY_LOCALE ?? 'en-AU';

// GTM
export const GTM_CODE = process.env.GATSBY_GTM_CODE;
export const GTM_ENABLED = APP_ENV !== 'development';

// Stripe
export const STRIPE_PUB_KEY = process.env.GATSBY_STRIPE_PUB_KEY;

// CMS
export const CMS_GRAPHQL_API = process.env.GATSBY_CMS_GRAPHQL_API;
export const CMS_GRAPHQL_API_KEY = process.env.GATSBY_CMS_GRAPHQL_API_KEY;

// RECAPTCHA
export const RECAPTCHA_SITE_KEY = String(process.env.GATSBY_RECAPTCHA_SITE_KEY);

export const ONE_TRUST_KEY = process.env.GATSBY_ONE_TRUST_KEY;
