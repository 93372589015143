import {
  PaletteColorOptions,
  ThemeOptions,
  createTheme as createMuiTheme,
} from '@material-ui/core/styles';

import { getCurrentLocale } from '../utils/language';

const currentLocale = getCurrentLocale();

// Colours
const greenPrimary: PaletteColorOptions = {
  main: currentLocale === 'en-GB' ? '#2e9175' : '#5dad16',
  dark: currentLocale === 'en-GB' ? '#29257c' : '#74C415',
};
const blue: PaletteColorOptions = {
  light: '#B0C7D6',
  main: '#12394F',
};

const lime: PaletteColorOptions = {
  main: '#CEFF9F',
};
const mint: PaletteColorOptions = {
  main: '#B4F2CB',
};
const violet: PaletteColorOptions = {
  main: '#7E71F5',
};
const custard: PaletteColorOptions = {
  main: '#F9F4DE',
};
const mandarin: PaletteColorOptions = {
  main: '#F1C40F',
};
const cream: PaletteColorOptions = {
  main: '#EFEDE9',
};
const danger: PaletteColorOptions = {
  main: '#FF3333',
  dark: '#ce2a2a',
};

const black: PaletteColorOptions = {
  main: '#081720',
  light: '#0817204D',
};
const gray: PaletteColorOptions = {
  light: '#F2F2F2',
  main: '#818181',
};

const createTheme = (options: ThemeOptions = {}) => {
  return createMuiTheme({
    typography: {
      fontFamily: [
        'Cabin',
        'Montserrat',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        fontFamily: 'Montserrat',
      },
      h2: {
        fontFamily: 'Montserrat',
      },
      h3: {
        fontFamily: 'Montserrat',
      },
      h4: {
        fontFamily: 'Montserrat',
      },
      h5: {
        fontFamily: 'Montserrat',
      },
      h6: {
        fontFamily: 'Montserrat',
      },
      button: {
        fontFamily: 'Montserrat',
      },
    },
    breakpoints: {
      values: {
        xs: 320,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1680,
      },
    },
    palette: {
      primary: greenPrimary,
      secondary: violet,
      error: danger,
      // Custom Colours
      blue,
      gray,
      lime,
      mint,
      violet,
      custard,
      mandarin,
      cream,
      danger,
      black,
    },
    appDrawer: {
      width: 225,
      breakpoint: 'lg',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '.blue-gradient': {
            background:
              'linear-gradient(180deg, #B0C7D6 0%, #A6BBC9 0.01%, #71808A 100%)',
          },
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'initial',
          '&.MuiButton-containedPrimary:active': {
            backgroundColor: '#ffffff',
          },
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: 6,
          '&:hover': {
            backgroundColor: 'none',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          '&$error': {
            color: danger.dark,
          },
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    ...options,
  });
};

export const theme = createTheme();
