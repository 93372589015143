export function calculateDayDateDiff(
  dateA: string | Date | number,
  dateB: string | Date | number,
): number | null {
  if (!dateA || !dateB) {
    return null;
  }

  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();

  return Math.abs(timestampB - timestampA) / 1000 / 3600 / 24;
}

export function removeTimeFromDate(date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function removeUserTimeZoneOffset(date: Date): Date {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
}
