import React from 'react';

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import { GTM_CODE, GTM_ENABLED } from '../environment';

type Props = {
  pageId: string;
};

const AnalyticsStub: React.FC<Props> = ({ children }) => {
  console.info('Analytics disabled in this environment');
  return <>{children}</>;
};

const AnalyticsGtm: React.FC<Props> = ({ pageId, children }) => {
  // data layer name set to initial page, to keep it consistent across user session + route changes
  const dataLayerName = getInitialPageId(pageId);
  const gtmParams = { id: GTM_CODE, dataLayerName };
  return <GTMProvider state={gtmParams}>{children}</GTMProvider>;
};

export function getInitialPageId(currentPageId: string): string {
  if (typeof window === 'undefined') {
    return currentPageId;
  }
  window.initialPageId ??= currentPageId;
  return window.initialPageId;
}

export const AnalyticsProvider: React.FC<Props> = GTM_ENABLED
  ? AnalyticsGtm
  : AnalyticsStub;
