import { HEADER_HEIGHT } from '../../components/header/header';
import { DEFAULT_LOCALE } from '../../environment';

export const scrollToElement = (element: HTMLElement, topOffset = 0): void => {
  const navHeight = parseInt(HEADER_HEIGHT.MOBILE);
  const documnetScrollOffset = document.documentElement.scrollTop;
  const offset =
    element.getBoundingClientRect().top +
    documnetScrollOffset -
    navHeight -
    topOffset;
  window.scrollTo({ top: offset, behavior: 'smooth' });
};

export const handleFocusField = (
  isMobile: boolean,
  element: HTMLElement,
  topOffset = 40, // label offset
): void => {
  if (isMobile) scrollToElement(element, topOffset);
};

export const handleAutoFocus = (
  isMobile: boolean,
  lastFieldError?: unknown,
  currentFieldValue?: unknown,
): boolean => isMobile && !lastFieldError && !currentFieldValue;

export const isAULocale = DEFAULT_LOCALE === 'en-AU';
export const isGBLocale = DEFAULT_LOCALE === 'en-GB';
