import { decode } from '@mapbox/polyline';
import distance from '@turf/distance';

import { defaultLocale } from './language';

const mapboxAccessToken =
  'pk.eyJ1IjoiZG9ndGFpbmVycyIsImEiOiJjbGJ1MXNodGcwZjU2M29rNmV0NGR0a255In0.w3A_bUaTTjrt0GN9EU12jQ';

const LONDON_BOX = {
  minLng: -0.510375,
  maxLng: 0.334015,
  minLat: 51.279707,
  maxLat: 51.672343,
};

const initialValuesForDistance = {
  distance: 0,
  goesThroughLondon: false,
  distanceThroughLondon: 0,
};

const isValidGPS = (gps: number[]): boolean => gps.length === 2;

type DrivingRouteInfo = {
  distance: number;
  goesThroughLondon: boolean;
  distanceThroughLondon: number;
};

type DrivingRouteThroughLondon = Omit<DrivingRouteInfo, 'distance'> & {
  point: number[];
};

export type CallbackFunction = () => void;

export type AddressProps = { description: string; place_id: string };

export type LocationResponse = {
  formatted_address: string;
  address_components: { long_name: string; types: string[] }[];
  geometry: { location: { lat: CallbackFunction; lng: CallbackFunction } };
};

export const fields = ['address_components', 'geometry', 'formatted_address'];

export const getGooglePlacePredicitions = async (
  input: string,
  country?: string,
): Promise<AddressProps[]> =>
  new Promise((resolve, reject) => {
    if (!input) {
      return reject('Need valid text input');
    }
    if (typeof window === 'undefined') {
      return reject('Need valid window object');
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input,
          fields,
          strictBounds: false,
          types: [defaultLocale === 'en-AU' ? 'postal_code' : 'address'],
          componentRestrictions: {
            country,
          },
        },
        resolve,
      );
    } catch (e) {
      reject(e);
    }
  });

export const getGooglePlaceDetails = async (
  placeId: string,
): Promise<LocationResponse> =>
  new Promise((resolve, reject) => {
    if (!placeId) reject('placeId not provided');

    try {
      new window.google.maps.places.PlacesService(
        document.createElement('div'),
      ).getDetails(
        {
          placeId,
          fields,
        },
        resolve,
      );
    } catch (e) {
      reject(e);
    }
  });

export const findAddressProperty = (
  address: LocationResponse['address_components'],
  property: string,
): string =>
  address.find(
    (address: { types: string[] }) =>
      address.types && address.types.includes(property),
  )?.long_name || '';

export const getDistanceBeetwenPlaces = (
  origin: string,
  destination: string,
): number | undefined => {
  const from = processGpsForDistance(origin); //lng, lat
  const to = findGpsForAirport(destination); //lng, lat

  if (!to || !from) {
    return;
  }

  return Math.ceil(distance(to, from));
};

export const processGpsForDistance = (origin: string): number[] =>
  origin
    .split(',')
    .map((el) => +el)
    .reverse();

const findGpsForAirport = (destination: string): number[] | undefined =>
  airportsGPS.find(({ id }) => id === destination)?.gps;

const calculateDrivingDistance = async (
  gps1: number[],
  gps2: number[],
): Promise<{ distance: number; routeGeometry: [number, number][] }> => {
  if (!isValidGPS(gps1) || !isValidGPS(gps2))
    return { distance: 0, routeGeometry: [] };
  try {
    const response = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${gps1[1]},${gps1[0]};${gps2[1]},${gps2[0]}?access_token=${mapboxAccessToken}`,
    );
    const data = await response.json();

    const distance = data.routes[0].distance / 1000; // Convert meters to kilometers
    const encodedGeometry = data.routes[0].geometry;
    const routeGeometry = decode(encodedGeometry);

    return { distance, routeGeometry };
  } catch (error) {
    console.error('Error calculating driving distance:', error);
    return { distance: 0, routeGeometry: [] };
  }
};

const calculateDrivingDistanceAndDetectLondonRoute = async (
  gps1: number[],
  gps2: number[],
): Promise<DrivingRouteInfo> => {
  if (!isValidGPS(gps1) || !isValidGPS(gps2)) return initialValuesForDistance;

  try {
    const { distance, routeGeometry } = await calculateDrivingDistance(
      gps1,
      gps2,
    );

    const { goesThroughLondon, distanceThroughLondon } =
      await routeGeometry.reduce<Promise<DrivingRouteThroughLondon>>(
        async (acc, curr) => {
          const accumulator = await acc;
          const [lat, lng] = curr;
          if (
            !accumulator.point.length ||
            (lng >= LONDON_BOX.minLng &&
              lng <= LONDON_BOX.maxLng &&
              lat >= LONDON_BOX.minLat &&
              lat <= LONDON_BOX.maxLat)
          ) {
            const goesThroughLondon = true;
            const distanceThroughLondon = await calculateDrivingDistance(
              accumulator.point,
              curr,
            );
            return {
              goesThroughLondon,
              distanceThroughLondon:
                accumulator.distanceThroughLondon +
                distanceThroughLondon.distance,
              point: curr,
            };
          }
          return { ...accumulator, point: curr };
        },
        Promise.resolve({
          goesThroughLondon: false,
          distanceThroughLondon: 0,
          point: [],
        }),
      );

    return { distance, goesThroughLondon, distanceThroughLondon };
  } catch (error) {
    console.error(
      'Error calculating driving distance and detecting London route:',
      error,
    );
    return initialValuesForDistance;
  }
};

export const totalDistance = (
  gps1: number[],
  gps2: number[],
): Promise<DrivingRouteInfo> => {
  const referencePoint = [51.4647, -0.502];

  const routeInfo = (async (): Promise<DrivingRouteInfo> => {
    const {
      distance: distance1,
      goesThroughLondon: inLondon1,
      distanceThroughLondon: londonDistance1,
    } = await calculateDrivingDistanceAndDetectLondonRoute(
      referencePoint,
      gps1,
    );
    const {
      distance: distance2,
      goesThroughLondon: inLondon2,
      distanceThroughLondon: londonDistance2,
    } = await calculateDrivingDistanceAndDetectLondonRoute(gps1, gps2);
    const {
      distance: distance3,
      goesThroughLondon: inLondon3,
      distanceThroughLondon: londonDistance3,
    } = await calculateDrivingDistanceAndDetectLondonRoute(
      gps2,
      referencePoint,
    );

    const totalDistance = distance1 + distance2 + distance3;
    const distanceLondon = londonDistance1 + londonDistance2 + londonDistance3;
    const isThroughLondon = inLondon1 || inLondon2 || inLondon3;

    return {
      distance: Number(totalDistance.toFixed(3)),
      goesThroughLondon: isThroughLondon,
      distanceThroughLondon: Number(distanceLondon.toFixed(3)),
    };
  })();
  return routeInfo;
};

const airportsGPS = [
  {
    id: 'BNE',
    gps: [153.1218, -27.3942],
  },
  {
    id: 'SYD',
    gps: [151.1819, -33.95],
  },
  {
    id: 'ADL',
    gps: [138.5373, -34.9382],
  },
  {
    id: 'MEL',
    gps: [144.8433, -37.6709],
  },
  {
    id: 'PER',
    gps: [115.9672, -31.9385],
  },
  {
    id: 'HBA',
    gps: [147.5075, -42.8364],
  },
  {
    id: 'CBR',
    gps: [149.1934, -35.3052],
  },
  {
    id: 'LST',
    gps: [147.2106, -41.5431],
  },
];
