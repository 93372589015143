import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { useStore } from '../../../../components/store';
import { API_URL, DEFAULT_LOCALE } from '../../../../environment';
import { isBrisbaneInWorkingHours } from '../../../../utils';
import { QuoteConfiguration, QuoteForm } from '../../quote.types';
import {
  GET_DOMESTIC_CONFIGURATION_QUERY,
  GET_INTERNATIONAL_CONFIGURATION_QUERY,
} from './query';
import {
  DomesticConfigurationResponse,
  InternationalConfigurationResponse,
} from './types';

export function useQuoteConfiguration(): {
  quoteConfiguration: QuoteConfiguration | null;
  updateQuoteConfiguration: (quote: QuoteForm) => Promise<void>;
} {
  const client = useApolloClient();

  const { quoteConfiguration, setQuoteConfiguration } = useStore(
    ({ quoteConfiguration, setQuoteConfiguration }) => ({
      quoteConfiguration,
      setQuoteConfiguration,
    }),
  );
  const updateQuoteConfiguration = useCallback(
    async (quote: QuoteForm) => {
      try {
        if (quote.quoteType === 'international') {
          if (quoteConfiguration?.quoteType === 'international') {
            return;
          }

          const {
            data: {
              international: {
                quoteConfiguration: {
                  allowBookingAppointments,
                  averageDaysForFullQuote,
                },
              },
            },
          } = await client.query<InternationalConfigurationResponse>({
            query: GET_INTERNATIONAL_CONFIGURATION_QUERY,
            variables: {
              franchiseCode: DEFAULT_LOCALE,
            },
          });

          setQuoteConfiguration({
            quoteType: 'international',
            allowBookingAppointments,
            averageDaysForFullQuote,
          });
        } else {
          if (!quote.contact.postcode && DEFAULT_LOCALE === 'en-AU') {
            return;
          }

          const { franchiseCode } = await fetch(
            `${API_URL}/franchises/postcode/${quote.contact.postcode}`,
          ).then((res) => res.json());

          const {
            data: {
              domestic: {
                quoteConfiguration: {
                  allowBookingAppointments,
                  averageDaysForFullQuote,
                  allowOnlinePayments,
                },
              },
            },
          } = await client.query<DomesticConfigurationResponse>({
            query: GET_DOMESTIC_CONFIGURATION_QUERY,
            variables: {
              franchiseCode,
            },
          });

          const postCode = Number(quote.contact.postcode);

          const allowChat =
            franchiseCode === 'BNE' &&
            postCode >= 1001 &&
            postCode <= 9729 &&
            isBrisbaneInWorkingHours();

          setQuoteConfiguration({
            quoteType: 'domestic',
            allowBookingAppointments,
            averageDaysForFullQuote,
            allowOnlinePayments,
            postcode: quote.contact.postcode,
            franchiseCode,
            allowChat,
            chatOptions: CHAT_LINKS[franchiseCode as keyof typeof CHAT_LINKS],
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [client],
  );
  return {
    quoteConfiguration,
    updateQuoteConfiguration,
  };
}

export const CHAT_LINKS = {
  BNE: {
    domestic: 'https://dogtainer.3cx.com.au:5001',
    export: 'https://dogtainer.3cx.com.au:5001/callus/#bneinternationalexpo',
    import: 'https://dogtainer.3cx.com.au:5001/callus/#bneinternationalimpo',
    corporate: 'https://dogtainer.3cx.com.au:5001/callus/#bnecorporate',
  },
  MEL: {
    domestic: 'https://dogtainer.3cx.com.au:5001/callus/#meldomestic',
    export: 'https://dogtainer.3cx.com.au:5001/callus/#melinternational',
    import: 'https://dogtainer.3cx.com.au:5001/callus/#melinternational',
  },
  DRW: {
    domestic: 'https://dogtainer.3cx.com.au:5001/callus/#drwall',
    export: 'https://dogtainer.3cx.com.au:5001/callus/#drwall',
    import: 'https://dogtainer.3cx.com.au:5001/callus/#drwall',
  },
  CMS: {
    domestic: 'https://dogtainer.3cx.com.au:5001/callus/#cnsenquiries',
    export: 'https://dogtainer.3cx.com.au:5001/callus/#cnsenquiries',
    import: 'https://dogtainer.3cx.com.au:5001/callus/#cnsenquiries',
  },
};
