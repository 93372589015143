import { navigate } from 'gatsby';
import React, { useMemo } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { useStore } from '../../../components/store';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useEnsureQuoteForm } from '../../../hooks/useEnsureQuoteForm';
import { defaultLocale, localiseUrl } from '../../../utils/language';
import QuoteRequestReceivedNoPrice from './quoteRequestReceivedNoPrice';
import QuoteRequestReceivedWithPrice from './quoteRequestReceivedWithPrice';
import commonStyles from './styles';

const QuoteRequestReceived: React.FC = () => {
  const commonClasses = commonStyles();
  const localeCountryId = defaultLocale.slice(-2);
  const bookingQuote = useStore((state) => state.bookingQuote);
  const sendBookAppointment = useAnalytics();

  const quoteCanBePaid = useMemo(
    () =>
      !bookingQuote?.onlineQuoteRestrictionReason &&
      bookingQuote?.totalCost &&
      localeCountryId !== 'NZ',
    [bookingQuote],
  );

  useEnsureQuoteForm();

  const onBookCall = () => {
    sendBookAppointment({
      event: 'bookAnAppointment',
    });
    navigate(localiseUrl('schedule-call'));
  };

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box marginBottom="21px">
            <Typography variant="h4">
              <b className={commonClasses.heading}>Quote request recieved</b>
            </Typography>
          </Box>
          {quoteCanBePaid ? (
            <QuoteRequestReceivedWithPrice onBookCall={onBookCall} />
          ) : (
            <QuoteRequestReceivedNoPrice onBookCall={onBookCall} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuoteRequestReceived;
