import { defaultLocale } from '../pages/page4';

type StringKeyValuePairs = { [key: string]: string };

export const renderTooltipForAU = (tooltip: string): string | undefined =>
  defaultLocale === 'en-AU' ? tooltip : undefined;

export const requiresPetAccommodationQuestion: StringKeyValuePairs = {
  AU: 'Do you require us to organise Pet Accommodation?',
  GB: 'Do you require us to organise Pet Boarding?',
  NZ: 'Do you require us to organise Pet Accommodation?',
};
