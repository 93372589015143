import React, { useContext, useEffect, useState } from 'react';

import { API_URL } from '../../../environment';
import { LoadingContext } from '../../../layouts/site.layout';

export const EmailsPreview: React.FC = () => {
  const { setOverlay } = useContext(LoadingContext);
  const emailId = new URLSearchParams(location.search).get('emailId');
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchEmailContent = async () => {
      try {
        setOverlay('Loading...');
        const response = await fetch(`${API_URL}/emails/${emailId}`);
        const data = await response.json();
        setHtmlContent(data.body);
        setOverlay(undefined);
      } catch (error) {
        setOverlay(undefined);
        console.error(error);
      }
    };

    fetchEmailContent();
  }, [emailId]);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
