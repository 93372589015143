import { navigate } from 'gatsby';
import React from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { WhiteButton } from '../../../components/buttons';
import { useStore } from '../../../components/store';
import { useEnsureQuoteForm } from '../../../hooks/useEnsureQuoteForm';
import { useQuoteAirports } from '../../../hooks/useQuoteAirpotrs';
import { articleForWord } from '../../../utils/articleForWord';
import { localiseUrl } from '../../../utils/language';
import { useQuoteConfiguration } from '../hooks/useQuoteConfiguration';
import commonStyles from './styles';

const CallBookingConfirmed: React.FC = () => {
  const commonClasses = commonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const bookingQuote = useStore((state) => state.bookingQuote);
  const { depatureAirport, arrivalAirport } = useQuoteAirports();
  const { quoteConfiguration } = useQuoteConfiguration();

  useEnsureQuoteForm();

  const onCancel = () => {
    const url = 'quote-confirmation';
    navigate(localiseUrl(url));
  };

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={commonClasses.header}>
            <Typography variant={matches ? 'h4' : 'h3'} gutterBottom>
              Thank you for scheduling a call
            </Typography>
            <Typography variant="h5">We will contact you soon.</Typography>
          </Box>

          <Box mb={4}>
            <Typography>
              You told us that you’re looking for a{' '}
              {bookingQuote?.options.isBreeder && 'breeder'} quote to transport
              {bookingQuote?.pets?.[0].breedId &&
                articleForWord(bookingQuote.pets[0].breedId)}
              <span className="highlight">
                {bookingQuote?.pets?.[0].breedId}
              </span>{' '}
              from <span className="highlight">{depatureAirport}</span> to{' '}
              <span className="highlight">{arrivalAirport}</span> on the{' '}
              <span className="highlight">
                {bookingQuote?.departure?.date}.
              </span>
            </Typography>
          </Box>

          {bookingQuote?.totalCost ? (
            <React.Fragment>
              <Box
                display="flex"
                justifyContent="space-between"
                mb={matches ? 23 : 25}
                flexDirection={matches ? 'column' : 'row'}
              >
                <Typography>
                  We gave you an estimated quote value for this booking of:
                </Typography>
                <Box textAlign={matches ? 'right' : 'left'}>
                  <Typography
                    variant={matches ? 'h5' : 'h4'}
                    className="highlight"
                  >
                    ${bookingQuote.totalCost.amount}
                  </Typography>
                </Box>
              </Box>

              <Box mb={matches ? 2.5 : 5}>
                <Typography className="highlight">
                  *The more detailed may differ from the price above. Due to the
                  nature of our business this will be completed manually by one
                  of our team members.
                </Typography>
              </Box>
            </React.Fragment>
          ) : (
            <Box mb={matches ? 8 : 12}>
              <Typography>
                We were unable to generate an estimate for this quote, we will
                be in touch soon regarding your request.
              </Typography>
            </Box>
          )}
          <Box className={commonClasses.averageWaitTime} mb={3}>
            <Typography className="highlight">
              The current average wait time for this service is:
            </Typography>
            <Typography variant={matches ? 'h5' : 'h4'} color="primary">
              {quoteConfiguration?.averageDaysForFullQuote || 7} days
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" mb={4}>
            {bookingQuote?.totalCost && (
              <Box mb={matches ? 2 : 0}>
                <WhiteButton variant="contained" fullWidth onClick={onCancel}>
                  Cancel call and book now
                </WhiteButton>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CallBookingConfirmed;
