import { navigate } from 'gatsby';
import { useEffect } from 'react';

import { useStore } from '../components/store';
import { localiseUrl } from '../utils/language';

export const useEnsureQuoteForm = (): void => {
  const quoteFormData = useStore((state) => state.bookingQuote);

  useEffect(() => {
    if (!quoteFormData) {
      navigate(localiseUrl('quote'));
    }
  }, []);
};
