import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
    },
  },
  headerParagraph: {
    marginTop: '1rem',
  },
  submitBtnWrapper: {
    marginTop: '1rem',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'initial',
      '& button': {
        width: '100%',
      },
    },
  },
  imageWrapper: {
    '&.MuiGrid-item': {
      padding: '3rem',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export { useStyles };
