import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { WhiteButton } from '../../../components/buttons';
import { useStore } from '../../../components/store';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useEnsureQuoteForm } from '../../../hooks/useEnsureQuoteForm';
import { localiseUrl } from '../../../utils/language';
import { PetSummary } from '../components/PetSummary';
import commonStyles from './styles';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 39,
  },
  total: {
    padding: 28,
    borderRadius: 12,
    background: '#fff',
    marginBottom: 54,
  },
  checkBoxLabel: {
    maxWidth: 328,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

const QuoteConfirmation: React.FC = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const bookingQuote = useStore((state) => state.bookingQuote);
  const sendConfirmationNext = useAnalytics();

  useEnsureQuoteForm();

  useEffect(() => {
    if (
      bookingQuote?.onlineQuoteRestrictionReason ||
      !bookingQuote?.totalCost
    ) {
      navigate(localiseUrl('quote-request-received'));
    }
  }, [bookingQuote]);

  const onNext = () => {
    const bookUrl = 'payment';
    sendConfirmationNext({
      event: 'confirmationNext',
    });
    navigate(localiseUrl(bookUrl));
  };

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Typography variant={matches ? 'h4' : 'h3'} gutterBottom>
              Confirmation
            </Typography>
          </Box>

          <Box>
            <PetSummary title="Summary" isEditable showPrice />
          </Box>

          <Box className={classes.total}>
            <Box display="flex" justifyContent="space-between" mt={1.5} mb={4}>
              <Typography variant="h6" gutterBottom>
                Total
              </Typography>
              <Typography variant="h6" gutterBottom>
                ${bookingQuote?.totalCost?.amount}
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="body2">
                Price shown is the total price, <br /> including additional fees
                and taxes
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection={!matches ? 'row' : 'column-reverse'}
            mb={4}
          >
            <Box mr={!matches ? 4 : 0} mt={matches ? 2 : 0}>
              <WhiteButton
                variant="outlined"
                fullWidth
                onClick={() => navigate(localiseUrl('quote-request-received'))}
              >
                Cancel
              </WhiteButton>
            </Box>

            <Button variant="contained" color="primary" onClick={onNext}>
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuoteConfirmation;
