/* eslint-disable */
// @ts-nocheck
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type GetFieldType<Obj, Path> =
  Path extends `${infer Left}.${infer Right}`
    ? Left extends keyof Obj
      ?
          | GetFieldType<Exclude<Obj[Left], undefined>, Right>
          | Extract<Obj[Left], undefined>
      : undefined
    : Path extends keyof Obj
    ? Obj[Path]
    : undefined;

export function getValue<
  TData extends object,
  TPath extends NestedKeyOf<TData>,
  TDefault = GetFieldType<TData, TPath>,
>(
  data: TData,
  path: TPath,
  defaultValue?: TDefault,
): GetFieldType<TData, TPath> | TDefault {
  const value = path
    .split('.')
    .reduce<GetFieldType<TData, TPath>>((value, key) => {
      const squareBracketsRegEx = /\[(.*?)\]/;

      // get number between square brackets
      const arrayItem = key.match(squareBracketsRegEx)?.[1];

      if (!arrayItem) return (value as any)?.[key];

      // get propery before square brackets
      const tempKey = key.replace(squareBracketsRegEx, '');
      return (value as any)?.[tempKey]?.[arrayItem];
    }, data as any);

  return value !== undefined ? value : (defaultValue as TDefault);
}

export function clearUrlParams(): void {
  if (typeof window === 'undefined') {
    return;
  }
  window.history.replaceState(
    {},
    document.title,
    location.origin + location.pathname,
  );
}
