import classNames from 'classnames';
import { navigate } from 'gatsby';
import React, { useMemo } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { CallButton } from '../../../components/buttons';
import { useStore } from '../../../components/store';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { localiseUrl } from '../../../utils/language';
import { PetSummary } from '../components/PetSummary';
import { useQuoteConfiguration } from '../hooks/useQuoteConfiguration';
import commonStyles from './styles';

const QuoteRequestReceivedNoPrice: React.FC<{ onBookCall: () => void }> = ({
  onBookCall,
}) => {
  const commonClasses = commonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const bookingQuote = useStore((state) => state.bookingQuote);
  const { quoteConfiguration } = useQuoteConfiguration();
  const sendConfirmBooking = useAnalytics();

  const isDomestic = useMemo(
    () => bookingQuote?.quoteType === 'domestic',
    [bookingQuote],
  );

  const onConfirmBooking = () => {
    const url = 'quote-confirmation';
    sendConfirmBooking({
      event: 'confirmBooking',
    });

    navigate(localiseUrl(url));
  };

  return (
    <Grid item xs={12}>
      <Box marginBottom="21px">
        <Typography variant="h6">
          <b className={commonClasses.mainSubHeading}>
            {isDomestic ? (
              <>
                Thank you. You’re well on your way. We will be in touch in{' '}
                <span className={commonClasses.green}>
                  {quoteConfiguration?.averageDaysForFullQuote || 7}
                </span>{' '}
                days.
              </>
            ) : (
              'One of our international pet travel consultants will be in touch by the next business day.'
            )}
          </b>
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={3}
        flexDirection={matches ? 'column' : 'row'}
      >
        <Typography className={commonClasses.subHeading}>
          We gave you an estimated quote value for this booking of:
        </Typography>
        <Box textAlign={matches ? 'right' : 'left'}>
          <Typography
            variant={matches ? 'h5' : 'h4'}
            className={classNames(commonClasses.price, 'highlight')}
          >
            <b>${bookingQuote?.totalCost?.amount}*</b>
          </Typography>
        </Box>
      </Box>
      <Box mb={7} display="flex" justifyContent="flex-end">
        <Box className={commonClasses.button}>
          <Box className={commonClasses.backgroundBorder} />
          <Button
            variant="contained"
            color="primary"
            className={commonClasses.bookCallButton}
            onClick={isDomestic ? onConfirmBooking : onBookCall}
          >
            <b>
              {isDomestic ? 'Complete purchase' : 'Book a call with our team'}
            </b>
          </Button>
        </Box>
      </Box>
      <Box>
        <PetSummary title={'Quote Details'} showPrice />
      </Box>
      {isDomestic && (
        <Box display="flex" justifyContent="flex-end" mb={5}>
          <Box className={commonClasses.button}>
            <Box className={commonClasses.backgroundBorder} />
            <CallButton
              onBookCall={onBookCall}
              variant="outlined"
              className={commonClasses.bookCallButton}
              title={<b>Book a call with our team</b>}
            />
          </Box>
        </Box>
      )}
      <Box mb={matches ? 2.5 : 5} className="highlight">
        <Typography className={commonClasses.disclaimer}>
          *The more detailed quote may differ from the price above. Due to the
          nature of our business this will be completed manually by one of our
          team members.
        </Typography>
      </Box>
    </Grid>
  );
};

export default QuoteRequestReceivedNoPrice;
