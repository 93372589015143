import { navigate } from 'gatsby';
import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { localiseUrl } from '../../../../utils/language';

type GeneralEnquiryProps = {
  submitSection: JSX.Element;
  recaptchaToken: string | null;
};

const GeneralEnquiry: React.FC<GeneralEnquiryProps> = ({
  submitSection,
  recaptchaToken,
}) => (
  <>
    {submitSection}

    {recaptchaToken && (
      <>
        <Grid item>
          <Typography>
            We don&apos;t take general enquiries online. The best way to contact
            us regarding general enquiries to speak directly with our helpful
            consultants. Click below to book a call.
          </Typography>
        </Grid>

        <Grid item>
          <Button
            onClick={() => navigate(localiseUrl('/schedule-call'))}
            variant="contained"
            color="primary"
          >
            Book a call with a consultant
          </Button>
        </Grid>
      </>
    )}
  </>
);

export default GeneralEnquiry;
