import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type ImagesQuery = {
  allFile: {
    edges: {
      node: ImageData;
    }[];
  };
};
type ImageData = {
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
  relativePath: string;
};

export function useImages(): {
  allImages: ImageData[];
  getImageData: (src: string) => IGatsbyImageData | undefined;
} {
  // Prepare all image paths
  const imagesQuery: ImagesQuery = useStaticQuery(
    graphql`
      query GetImages {
        allFile(
          filter: {
            relativePath: { regex: "/.(gif|jpe?g|tiff?|png|webp|bmp)$/i" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              relativePath
            }
          }
        }
      }
    `,
  );
  const allImages: ImageData[] = imagesQuery.allFile.edges.map((e) => e.node);
  function getImageData(src: string): IGatsbyImageData | undefined {
    // No translation content available for this image
    if (src === '$') {
      return;
    }
    const image = allImages.find((i) => i.relativePath === src);
    if (!image) {
      console.warn(`Could not find target image at path "${src}"`);
      return undefined;
    }
    return image.childImageSharp.gatsbyImageData;
  }
  return {
    allImages,
    getImageData,
  };
}
