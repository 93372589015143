import React, { useMemo } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { CallButton } from '../../../components/buttons';
import { defaultLocale } from '../../../utils/language';
import { PetSummary } from '../components/PetSummary';
import { useQuoteConfiguration } from '../hooks/useQuoteConfiguration';
import commonStyles from './styles';

const QuoteRequestReceivedNoPrice: React.FC<{ onBookCall: () => void }> = ({
  onBookCall,
}) => {
  const commonClasses = commonStyles();
  const localeCountryId = defaultLocale.slice(-2);
  const { quoteConfiguration } = useQuoteConfiguration();

  const calculateDays = useMemo(
    () =>
      localeCountryId === 'GB' ? (
        <span className={commonClasses.ukGreen}> 2 </span>
      ) : (
        <span className={commonClasses.green}>
          {' '}
          {quoteConfiguration?.averageDaysForFullQuote || 7}{' '}
        </span>
      ),
    [quoteConfiguration, localeCountryId],
  );

  return (
    <Grid>
      <Box mb={4}>
        <Typography variant="h6">
          <b className={commonClasses.mainSubHeading}>
            Thank you! You’re well on your way.
          </b>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography className={commonClasses.subHeading}>
          To ensure you get the most accurate price possible, one of our
          consultants will calculate your quote personally.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography className={commonClasses.subHeading}>
          We will get back to you in the next
          {calculateDays}
          days with your pricing, or if you’d like to select your own time, you
          can book a call with our team.
        </Typography>
      </Box>

      <Box>
        <PetSummary title={'Quote Details'} />
      </Box>

      <Box mb={4} display="flex" justifyContent="flex-end">
        <Box className={commonClasses.button}>
          <Box className={commonClasses.backgroundBorder} />
          <CallButton
            className={commonClasses.bookCallButton}
            onBookCall={onBookCall}
            variant="outlined"
            title={<b>Book a call with our team</b>}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default QuoteRequestReceivedNoPrice;
