export const isBrisbaneInWorkingHours = (): boolean => {
  const brisbaneTime = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Australia/Brisbane',
    }),
  );

  const brisbaneDay = brisbaneTime.getDay();
  const brisbaneHour = brisbaneTime.getHours();

  return (
    !(brisbaneHour < 9 || brisbaneHour > 18) &&
    brisbaneDay > 0 &&
    brisbaneDay < 6
  );
};
