import { LdJsonScript } from '../components/seo.utils';
import { BreadcrumbsList } from './page.template';

const generateBreadcrumbs = (list: BreadcrumbsList): LdJsonScript => {
  return {
    type: 'application/ld+json',
    content: {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: list.map(({ position, name, item }) => ({
        '@type': 'ListItem',
        position: position,
        name: name,
        item: item,
      })),
    },
  };
};

export function isInFrame(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return false;
  }
}

export { generateBreadcrumbs };
