import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    justifyContent: 'center',
    flexWrap: 'nowrap',

    paddingTop: '100px',

    margin: '0 auto',
    maxWidth: '1280px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '40px 16px 0',
    },
  },
  infoContainer: {
    minHeight: '330px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      minHeight: 'initial',

      '&.MuiGrid-item': {
        paddingBottom: '0',
      },
    },
  },
  heading: {
    marginBottom: '36px',
    fontWeight: 700,
  },
  textContainer: {
    '& p': {
      margin: '24px 0',
      [theme.breakpoints.down('xs')]: {
        margin: '18px 0',
      },
    },
  },
  paragraph: {
    margin: '24px 0',
    [theme.breakpoints.down('xs')]: {
      margin: '18px 0',
    },
  },
  imageWrapper: {
    padding: '0 2.5rem',
    maxHeight: '330px',

    [theme.breakpoints.down('xs')]: {
      padding: '0 0.5rem',
      width: '100%',
      maxWidth: '330px',
      margin: '0 auto',
    },
  },
  desktopButtonsWrapper: {
    gap: '16px',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileButtonsWrapper: {
    display: 'none',

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  },
}));

export { useStyles };
