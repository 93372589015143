import classNames from 'classnames';
import React from 'react';

import { AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useIsPageTop } from '../../hooks/useIsPageTop';
import { Nav } from './components/nav';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    transition: 'all 200ms ease-in',
  },
  whiteBg: {
    backgroundColor: 'white',
  },
}));

export const HEADER_HEIGHT = {
  MOBILE: '56px',
  DESKTOP: '64px',
};

export const Header: React.FC<{ pageId: string }> = ({ pageId }) => {
  const classes = useStyles();
  const isPageTop = useIsPageTop();

  return (
    <AppBar
      position="sticky"
      className={classNames(classes.root, !isPageTop && classes.whiteBg)}
    >
      <Nav isPageTop={isPageTop} pageId={pageId} />
    </AppBar>
  );
};
