import { useCallback, useEffect, useRef, useState } from 'react';

import { useIsPageTop } from './useIsPageTop';

type ScrollToError = {
  isFieldInFocus: (
    name: string,
  ) => React.MutableRefObject<HTMLInputElement | null> | undefined;
  fieldInFocus: string;
  setFieldInFocus: React.Dispatch<React.SetStateAction<string>>;
  isErrorsVisible: boolean;
  setErrorsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useScrollToError(formElementId: string): ScrollToError {
  const [fieldInFocus, setFieldInFocus] = useState<string>('');
  const [isErrorsVisible, setErrorsVisible] = useState<boolean>(false);
  const scrollToRef = useRef<null | HTMLInputElement>(null);
  const isTop = useIsPageTop();

  const isFieldInFocus = useCallback(
    (name) => (fieldInFocus === name ? scrollToRef : undefined),
    [fieldInFocus],
  );

  useEffect(() => {
    if (!isErrorsVisible || !fieldInFocus || !scrollToRef.current) {
      return;
    }

    scrollToRef.current.scrollIntoView();
    setFieldInFocus('');
  }, [fieldInFocus, scrollToRef, isErrorsVisible, setFieldInFocus]);

  useEffect(() => {
    if (!isErrorsVisible) {
      return;
    }

    const element = document.getElementById(formElementId);
    if (element) {
      isTop
        ? element?.scrollIntoView()
        : window.scrollTo(0, element.offsetTop - 64);
    }
  }, [isErrorsVisible]);

  return {
    fieldInFocus,
    isFieldInFocus,
    setFieldInFocus,
    isErrorsVisible,
    setErrorsVisible,
  };
}
