import React from 'react';

import { Container, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FooterCol, renderFooterRow } from './footer.utils';

type DesktopFooterProps = {
  verticalFooter?: FooterCol[];
  horizontalFooter?: FooterCol[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    position: 'relative',
    bottom: 0,
  },
  list: {
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
      '& a': {
        color: theme.palette.black.main,
      },
    },
  },
  horizontalFooterWrapper: {
    flexDirection: 'column',
    paddingTop: '16px',
  },
  horizontalColumns: {
    display: 'contents',
  },
  horizontalList: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    padding: 0,
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
      '& a': {
        color: theme.palette.black.main,
      },
    },
  },
  columns: {
    flexBasis: '20%',
    '&:first-of-type': {
      flexBasis: '25%',
    },
    '&:last-of-type': {
      flexBasis: '15%',
    },
  },
}));

export const DesktopFooter: React.FC<DesktopFooterProps> = ({
  verticalFooter,
  horizontalFooter,
}) => {
  const classes = useStyles();

  return (
    <Container style={{ marginBottom: '3rem' }}>
      <Grid container className={classes.root}>
        {verticalFooter &&
          verticalFooter.map((col, colIndex) => (
            <Grid
              item
              key={`widget_col_${colIndex}`}
              className={classes.columns}
            >
              <List className={classes.list} dense={true}>
                {col.map((row, rowIndex) => (
                  <ListItem
                    key={`widget_row_${colIndex}_${rowIndex}_${row.label}`}
                  >
                    {renderFooterRow(row)}
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
      </Grid>
      <Grid container className={classes.horizontalFooterWrapper}>
        {horizontalFooter &&
          horizontalFooter.map((col, colIndex) => (
            <Grid
              item
              key={`widget_col_${colIndex}`}
              className={classes.horizontalColumns}
            >
              <List className={classes.horizontalList} dense={true}>
                {col.map((row, rowIndex) => (
                  <ListItem
                    key={`widget_row_${colIndex}_${rowIndex}_${row.label}`}
                    className={classes.columns}
                  >
                    {renderFooterRow(row)}
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};
