export type Country = {
  id: string;
  label: string;
};

const phoneRegExp =
  /^\s*\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?\s*$/;
const nameRegExp = /^\s*([a-zA-Z]+[ -]?)*[a-zA-Z]+\s*$/;
const emailRegExp =
  /(([^<>()\[\]\\.,;:@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9 ]+\.)+[a-zA-Z ]{2,}\s*))$/;

const spaceInsideRegex = /^\s*[^ "]+\s*$/;

const getPostcodeRegex = (countryId?: Country['id']): RegExp => {
  switch (countryId) {
    case 'AU':
      return /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/;
    case 'NZ':
      return /^\d{4}$/;
    case 'GB':
      return /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
    default:
      // just check non-blank, non-whitespace
      return /^(?!\s*$).+/;
  }
};

const getSpaceErrorMessage = (input: string): string =>
  `${input
    .split(' ')
    .filter(Boolean)
    .reduce(
      (acc: string, curr: string, index: number) =>
        `${acc}${index ? ' and ' : ' '}"${curr}"`,
      'There is a space present between',
    )} please remove the space or edit your email address.`;

export {
  phoneRegExp,
  emailRegExp,
  nameRegExp,
  spaceInsideRegex,
  getSpaceErrorMessage,
  getPostcodeRegex,
};
