import React from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import IconCheck from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { SubmitStatus } from '../../dropzone.types';
import useStyles from './fileCard.styles';

type FileCardProps = {
  children: string;
  status: SubmitStatus;
  onClikRemove: (fileName: string) => void;
};

const FileCard: React.FC<FileCardProps> = ({
  children,
  onClikRemove,
  status,
}) => {
  const classes = useStyles();

  const statusIcon = {
    idle: <></>,
    pending: <CircularProgress size="16px" />,
    failed: (
      <Box component="span" className={classes.failedIcon}>
        <HighlightOffIcon fontSize="small" />
      </Box>
    ),
    succeeded: (
      <Box component="span" className={classes.succeededIcon}>
        <IconCheck fontSize="small" />
      </Box>
    ),
  };

  return (
    <Grid className={classes.fileCardContainer}>
      <Grid className={classes.fileNameWrapper}>
        <Box className={classes.statusIconWrapper}>{statusIcon[status]}</Box>
        <Typography>{children}</Typography>
      </Grid>

      {status !== 'pending' && (
        <IconButton size="small" onClick={() => onClikRemove(children)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Grid>
  );
};

export default FileCard;
