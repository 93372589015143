import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { NestedKeyOf, getValue } from '../../utils/data';
import { calculateDayDateDiff } from '../../utils/date';

export type ErrorMsg = { field: string; message: string };

export const parseErrors = (
  obj: Record<string, unknown>,
  parents: string[] = [],
): ErrorMsg[] => {
  const errorMsgs: ErrorMsg[] = [];
  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      continue;
    }
    if (typeof value === 'string') {
      errorMsgs.push({ field: [...parents, key].join('.'), message: value });
    }
    if (typeof value === 'object') {
      errorMsgs.push(
        ...parseErrors(value as Record<string, unknown>, [...parents, key]),
      );
    }
  }
  return errorMsgs;
};

export const parseTouched = (
  obj: Record<string, unknown>,
  parents: string[] = [],
): string[] => {
  const touchedFieldNames: string[] = [];
  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      continue;
    }
    if (typeof value === 'boolean') {
      touchedFieldNames.push([...parents, key].join('.'));
    }
    if (typeof value === 'object') {
      touchedFieldNames.push(
        ...parseTouched(value as Record<string, unknown>, [...parents, key]),
      );
    }
  }
  return touchedFieldNames;
};

export const generateDate = (d = new Date(), offsetDay = 0): Date => {
  d.setDate(d.getDate() + offsetDay);
  return d;
};

export const generateMaxDate = (d = new Date(), offsetMonth = 0): Date => {
  d.setMonth(d.getMonth() + offsetMonth);
  return d;
};

export const validateDatesByDayDiff =
  (datePath: string, numDays: number, isRequired = false, isGreather = true) =>
  (
    inputDate: string | undefined,
    context: yup.TestContext<AnyObject>,
  ): boolean => {
    if (!inputDate) return !isRequired;

    const comparedDate = getValue(
      context,
      datePath as NestedKeyOf<yup.TestContext<AnyObject>>,
    );

    if (!comparedDate) return false;

    const dateA = new Date(comparedDate);
    const dateB = new Date(inputDate);

    const dateDiff = calculateDayDateDiff(dateA, dateB) ?? 0;

    return (
      dateDiff > numDays - 1 && (isGreather ? dateB > dateA : dateB < dateA)
    );
  };
