import { FileRejection } from 'react-dropzone';

import { API_URL } from '../../environment';
import { FileValue } from '../../forms/booking/booking.types';
import {
  AwsData,
  AwsDataItem,
  DropzoneFile,
  PreparedFile,
  PreparedFilesData,
  Validation,
} from './dropzone.types';

export const onDropValidation = (
  oldFiles: DropzoneFile[],
  newFiles: File[],
  fileRejections: Array<FileRejection>,
  maxFiles: number,
  maxSize: number,
): Validation => {
  let errorMessage = undefined;
  let uniqueAcceptedFiles = newFiles.filter(
    ({ name: newFileName }) =>
      !oldFiles.some(({ name: oldFileName }) => newFileName === oldFileName),
  );

  const hasOtherError = !!fileRejections.length;
  if (hasOtherError) {
    const { message, code } = fileRejections?.[0].errors?.[0];
    errorMessage = message;

    if (code === 'file-too-large')
      errorMessage = `File is larger than ${maxSize / 1000000}MB`;
    else if (code === 'too-many-files')
      errorMessage = `Too many files. Max: ${maxFiles}`;
  }

  const hasSameFilesError = uniqueAcceptedFiles.length !== newFiles.length;
  if (hasSameFilesError) errorMessage = `Same files.`;

  const hasMaxFilesError =
    maxFiles > 0 ? newFiles.length + oldFiles.length > maxFiles : false;
  if (hasMaxFilesError) {
    errorMessage = `Too many files. Max: ${maxFiles}`;
    uniqueAcceptedFiles = [];
  }

  return [uniqueAcceptedFiles, errorMessage];
};

export const createFileFromBlob = async ({
  blobURL,
  name,
  type,
}: DropzoneFile): Promise<File> => {
  const file = await fetch(blobURL as string)
    .then((file) => file.blob())
    .then((blobFile) => new File([blobFile], name, { type }));

  return file;
};

export const preparePayloadsVariables = async (
  filesData: AwsDataItem,
  file: DropzoneFile,
): Promise<PreparedFile | undefined> => {
  try {
    const formData = new FormData();

    const { fields, url, key } = filesData;

    Object.entries(fields).forEach(([field, value]) =>
      formData.append(field, value as string),
    );

    const preparedFile = await createFileFromBlob(file);

    formData.append('file', preparedFile);

    return { name: file.name, formData, url, key };
  } catch (e) {
    alert(`Error on fetching blob file: ${file.name}.`);
    return;
  }
};

export const prepareFilesData = (files: DropzoneFile[]): PreparedFilesData => ({
  files: files.map(({ name, type }) => ({ name, type })),
});

export async function fetchAwsFilesParamsBooking(
  files: DropzoneFile[],
  quoteId: string,
): Promise<AwsData> {
  return await fetch(`${API_URL}/bookings/${quoteId}/get-file-post-urls`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(prepareFilesData(files)),
  }).then((res) => res.json());
}

export async function fetchAwsFilesParamsContacts(
  files: DropzoneFile[],
): Promise<AwsData> {
  return await fetch(`${API_URL}/contacts/get-file-post-urls`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify({
      formData: prepareFilesData(files),
    }),
  }).then((res) => res.json());
}

export function prepareInitDropzoneValue(value: FileValue[]): DropzoneFile[] {
  return value.map(
    ({ name, key }) =>
      ({
        name: name || key.substring(key.indexOf('-') + 1),
        key,
        status: 'succeeded',
      } as DropzoneFile),
  );
}

export function prepareFieldValue(files: DropzoneFile[]): FileValue[] {
  const succeededFiles = files.filter((file) => file.status === 'succeeded');
  return succeededFiles.map(
    ({ name, key }) =>
      ({
        name,
        key,
      } as FileValue),
  );
}
