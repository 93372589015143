import { useIntl } from 'react-intl';

type SiteMessage = (id: string, fallback: string) => string;

export function useSiteMessage(): SiteMessage {
  const intl = useIntl();
  return (id: string, fallback: string) =>
    Object.keys(intl.messages).includes(id)
      ? String(intl.messages[id])
      : fallback;
}
