const petTypes = ['dog', 'cat'];

export const isExtraDataMandatory = (
  localeCountryId: string,
  quoteType: 'domestic' | 'international',
  pet?: string,
): boolean =>
  pet
    ? petTypes.includes(pet) &&
      quoteType === 'international' &&
      localeCountryId === 'NZ'
    : false;
