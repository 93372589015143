import { navigate } from 'gatsby';
import React, { ReactNode } from 'react';

import Button from '@material-ui/core/Button';
import { Theme, withStyles } from '@material-ui/core/styles';

import { localiseUrl } from '../utils/language';

export const WhiteButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.black.main,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}))(Button);

type CallButtonProps = {
  title?: ReactNode;
  className?: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  onBookCall?: () => void;
};

export const CallButton: React.FC<CallButtonProps> = (props) => {
  const {
    title = 'Book a call with our team',
    variant = 'contained',
    className,
    onBookCall,
  } = props;

  const onClick = () => {
    onBookCall?.();
    navigate(localiseUrl('schedule-call'));
  };

  return (
    <WhiteButton variant={variant} onClick={onClick} className={className}>
      {title}
    </WhiteButton>
  );
};
