/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

export const Chat = ({
  url,
  showChat = true,
}: {
  url: string;
  showChat?: boolean;
}): JSX.Element => (
  <React.Fragment>
    {/* @ts-ignore */}
    <call-us
      style={{
        display: showChat ? 'block' : 'none',
        position: 'fixed',
        right: '24px',
        bottom: '96px',
        fontFamily: 'Arial',
        zIndex: 99999,
        '--call-us-form-header-background': '#373737',
        '--call-us-main-button-background': '#0596d4',
        '--call-us-client-text-color': '#d4d4d4',
        '--call-us-agent-text-color': '#eeeeee',
        '--call-us-form-height': '330px',
      }}
      id="wp-live-chat-by-3CX"
      channel-url={url}
      files-url={url}
      minimized="true"
      animation-style="none"
      party="bnedomestic"
      minimized-style="BubbleRight"
      allow-call="false"
      allow-video="false"
      allow-soundnotifications="true"
      enable-mute="true"
      enable-onmobile="true"
      offline-enabled="true"
      enable="true"
      ignore-queueownership="false"
      authentication="both"
      operator-name="Support"
      show-operator-actual-name="true"
      channel="phone"
      aknowledge-received="true"
      gdpr-enabled="false"
      gdpr-message="I agree that my personal data to be processed and for the use of cookies in order to engage in a chat processed by Dogtainers, for the purpose of Chat/Support for the time of 30 day(s) as per the GDPR."
      message-userinfo-format="both"
      message-dateformat="both"
      start-chat-button-text="Chat"
      window-title="Live Chat & Talk"
      button-icon-type="Default"
      invite-message="Hello! How can we help you today?"
      authentication-message="Could we have your name and email?"
      unavailable-message="We are away, leave us a message!"
      offline-finish-message="We received your message and we'll contact you soon."
      ending-message="Your session is over. Please feel free to contact us again!"
      greeting-visibility="none"
      greeting-offline-visibility="none"
      chat-delay="2000"
      offline-name-message="Could we have your name?"
      offline-email-message="Could we have your email?"
      offline-form-invalid-name="I'm sorry, the provided name is not valid."
      offline-form-maximum-characters-reached="Maximum characters reached"
      offline-form-invalid-email="I'm sorry, that doesn't look like an email address. Can you try again?"
      enable-direct-call="false"
      enable-ga="false"
    >
      {/* @ts-ignore */}
    </call-us>
  </React.Fragment>
);
