import { useEffect, useState } from 'react';

import { useStore } from '../components/store';
import { Airport } from '../forms/quote/quote.types';

type QuoteAirports = {
  arrivalAirport: string;
  depatureAirport: string;
};

export const useQuoteAirports = (): QuoteAirports => {
  const quoteFormData = useStore((state) => state.bookingQuote);

  const [depatureAirport, setDepatureAirport] = useState<string>('');
  const [arrivalAirport, setArrivalAirport] = useState<string>('');

  useEffect(() => {
    if (quoteFormData) {
      fetch('/data/airports.json').then((response) => {
        response.json().then((body: Airport[]) => {
          setDepatureAirport(
            body.find((x) => x.id === quoteFormData?.departure?.airportId)
              ?.label as string,
          );
          setArrivalAirport(
            body.find((x) => x.id === quoteFormData?.arrival?.airportId)
              ?.label as string,
          );
        });
      });
    }
  }, []);

  return {
    depatureAirport,
    arrivalAirport,
  };
};
