import * as yup from 'yup';

import { calculateDayDateDiff } from '../../utils/date';
import {
  emailRegExp,
  getSpaceErrorMessage,
  nameRegExp,
  phoneRegExp,
  spaceInsideRegex,
} from '../utils/regex';

export const validationSchema = yup.object().shape({
  //Required section
  nameFirst: yup
    .string()
    .matches(nameRegExp, 'Please enter valid name')
    .min(2, 'Too Short!')
    .max(40)
    .required('First name is required'),
  nameLast: yup
    .string()
    .matches(nameRegExp, 'Please enter valid last name')
    .min(2, 'Too Short!')
    .max(40)
    .required('Last name is required'),
  email: yup
    .string()
    .max(50)
    .matches(emailRegExp, 'Please enter valid email')
    .matches(spaceInsideRegex, (value) => getSpaceErrorMessage(value.value))
    .required('Email is required'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required'),
  contactSubject: yup.string().required('Please select a subject'),

  // Existing Quote Enquiry (isQuoteReceived, quoteReferenceNumber, idealTravelDate, question, isContactRequired, submitQuoteDate, stillProcessEnquiry)
  existingQuoteEnquiry: yup.object().when(['contactSubject'], {
    is: (contactSubject: string) => contactSubject === 'existingQuoteEnquiry',
    then: yup.object().shape({
      isQuoteReceived: yup.boolean().required('Required field'),
      quoteReferenceNumber: yup.string().when(['isQuoteReceived'], {
        is: (isQuoteReceived: boolean) => isQuoteReceived,
        then: yup.string().required('Quote Reference Number is required'),
      }),
      idealTravelDate: yup
        .date()
        .when(['isQuoteReceived', 'submitQuoteDate', 'stillProcessEnquiry'], {
          is: (
            isQuoteReceived: boolean,
            submitQuoteDate: string,
            stillProcessEnquiry: boolean,
          ) =>
            isQuoteReceived ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) < 7 &&
              stillProcessEnquiry) ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) >= 7),
          then: yup
            .date()
            .required('Please select an Ideal Travel Date')
            .test(
              'idealTravelDate',
              'Date must be greater than today',
              (selectedDate) =>
                (selectedDate &&
                  new Date().getTime() < selectedDate.getTime()) ??
                false,
            ),
        }),
      stillProcessEnquiry: yup
        .boolean()
        .when(['isQuoteReceived', 'submitQuoteDate'], {
          is: (isQuoteReceived: boolean, submitQuoteDate: string) =>
            !isQuoteReceived &&
            submitQuoteDate &&
            Math.floor(
              calculateDayDateDiff(submitQuoteDate, new Date()) as number,
            ) < 7,
          then: yup.boolean().required('Please select field'),
        }),
      submitQuoteDate: yup.date().when(['isQuoteReceived'], {
        is: (isQuoteReceived: boolean) => !isQuoteReceived,
        then: yup
          .date()
          .required('Please select a date')
          .test(
            'submitQuoteDate',
            'Date must be less than today',
            (selectedDate) =>
              (selectedDate && new Date().getTime() > selectedDate.getTime()) ??
              false,
          ),
      }),
      question: yup
        .string()
        .when(['isQuoteReceived', 'submitQuoteDate', 'stillProcessEnquiry'], {
          is: (
            isQuoteReceived: boolean,
            submitQuoteDate: string,
            stillProcessEnquiry: boolean,
          ) =>
            isQuoteReceived ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) < 7 &&
              stillProcessEnquiry) ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) >= 7) ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) < 7 &&
              stillProcessEnquiry),
          then: yup.string().required('Question is required'),
        }),
      isContactRequired: yup
        .boolean()
        .when(['isQuoteReceived', 'submitQuoteDate', 'stillProcessEnquiry'], {
          is: (
            isQuoteReceived: boolean,
            submitQuoteDate: string,
            stillProcessEnquiry: boolean,
          ) =>
            isQuoteReceived ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) < 7 &&
              stillProcessEnquiry) ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) >= 7) ||
            (!isQuoteReceived &&
              submitQuoteDate &&
              Math.floor(
                calculateDayDateDiff(submitQuoteDate, new Date()) as number,
              ) < 7 &&
              stillProcessEnquiry),
          then: yup.boolean().required('Required field'),
        }),
    }),
  }),

  // Job Application Enquiry (role, resume)
  jobApplicationEnquiry: yup.object().when(['contactSubject'], {
    is: (contactSubject: string) => contactSubject === 'jobApplicationEnquiry',
    then: yup.object().shape({
      role: yup
        .string()
        .required('Job position is required')
        .min(5, 'Too Short!'),
      resume: yup
        .array()
        .min(1, 'Please upload a file')
        .required('Please upload a file'),
    }),
  }),

  //Feedback (rating, experience, isContactRequired)
  feedback: yup.object().when(['contactSubject'], {
    is: (contactSubject: string) => contactSubject === 'feedback',
    then: yup.object().shape({
      rating: yup.string().required('Rating is required'),
      experience: yup.string().required('Experience is required'),
      isContactRequired: yup.boolean().required('Required field'),
    }),
  }),

  // Media Enquiry (companyName, mediaType, print, digital, podcastRadio, television, guestAppearance)
  mediaEnquiry: yup.object().when(['contactSubject'], {
    is: (contactSubject: string) => contactSubject === 'mediaEnquiry',
    then: yup.object().shape({
      companyName: yup.string().required('Company Name is required'),
      mediaType: yup
        .object({
          print: yup.boolean(),
          digital: yup.boolean(),
          podcastRadio: yup.boolean(),
          televisionOpportunity: yup.boolean(),
          guestAppearance: yup.boolean(),
        })
        .test(
          'mediaType',
          'Please select at least one Media Type',
          (mediaType) =>
            (mediaType.print ||
              mediaType.digital ||
              mediaType.podcastRadio ||
              mediaType.televisionOpportunity ||
              mediaType.guestAppearance) as boolean,
        ),
      print: yup.object().when(['mediaType'], {
        is: (mediaType: Record<string, boolean>) => mediaType?.print === true,
        then: yup.object().shape({
          printPublicationDate: yup.date().required('Please select a date'),
          publicationTopic: yup
            .string()
            .required('Publication Topic is required'),
          publicationBrief: yup
            .string()
            .required('Publication Brief is required'),
        }),
      }),
      digital: yup.object().when(['mediaType'], {
        is: (mediaType: Record<string, boolean>) => mediaType?.digital === true,
        then: yup.object().shape({
          digitalCompanyWebsite: yup
            .string()
            .required('Company website is required'),
          digitalPublicationDate: yup.date().required('Please select a date'),
          digitalTopic: yup.string().required('Digital Topic is required'),
          digitalBrief: yup.string().required('Digital Brief is required'),
        }),
      }),
      podcastRadio: yup.object().when(['mediaType'], {
        is: (mediaType: Record<string, boolean>) =>
          mediaType?.podcastRadio === true,
        then: yup.object().shape({
          podcastCompanyWebsite: yup
            .string()
            .required('Company website is required'),
          podcastRadioRecordingDate: yup
            .date()
            .required('Please select a date'),
          podcastRadioTopic: yup
            .string()
            .required('Podcast / Radio Topic is required'),
          podcastRadioBrief: yup
            .string()
            .required('Podcast / Radio Brief is required'),
        }),
      }),
      television: yup.object().when(['mediaType'], {
        is: (mediaType: Record<string, boolean>) =>
          mediaType?.televisionOpportunity === true,
        then: yup.object().shape({
          televisionCompanyWebsite: yup
            .string()
            .required('Company website is required'),
          televisionRecordingDate: yup.date().required('Please select a date'),
          televisionTopic: yup
            .string()
            .required('Television Topic is required'),
          televisionRadioBrief: yup
            .string()
            .required('Television Brief is required'),
        }),
      }),
      guestAppearance: yup.object().when(['mediaType'], {
        is: (mediaType: Record<string, boolean>) =>
          mediaType?.guestAppearance === true,
        then: yup.object().shape({
          eventWebsite: yup.string().required('Event website is required'),
          eventDate: yup.date().required('Please select a date'),
          eventTopic: yup.string().required('Event Topic is required'),
          eventDetails: yup.string().required('Event details are required'),
          appearanceDetails: yup
            .string()
            .required('Appearance details are required'),
        }),
      }),
    }),
  }),
});
