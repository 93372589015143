import { useLayoutEffect, useState } from 'react';

export function useIsPageTop(): boolean {
  const [isTop, setIsTop] = useState(true);
  function handleScroll() {
    const val = document.body?.getBoundingClientRect();
    setIsTop(val.y === 0);
  }
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isTop;
}
