import React from 'react';

import { Container, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FooterCol, renderFooterRow } from './footer.utils';

type MobileFooterProps = {
  footerColumns: FooterCol[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    position: 'relative',
    bottom: 0,
  },
  list: {
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
      '& a': {
        color: theme.palette.black.main,
      },
    },
  },
  columns: {
    flexBasis: '33.3333%',
    '@media (max-width:768px)': {
      flexBasis: '50%',
    },
    '@media (max-width:448px)': {
      flexBasis: '100%',
    },
  },
}));

export const MobileFooter: React.FC<MobileFooterProps> = ({
  footerColumns,
}) => {
  const classes = useStyles();

  return (
    <Container style={{ marginBottom: '3rem' }}>
      <Grid container className={classes.root}>
        {footerColumns.map((col, colIndex) => (
          <Grid item key={`widget_col_${colIndex}`} className={classes.columns}>
            <List className={classes.list} dense={true}>
              {col.map((row, rowIndex) => (
                <ListItem
                  key={`widget_row_${colIndex}_${rowIndex}_${row.label}`}
                >
                  {renderFooterRow(row)}
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
