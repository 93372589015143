import { isOnServer } from './ssr';

export function getStorageItem<T>(key: string, defaultValue: T): T {
  if (isOnServer()) {
    return defaultValue;
  }
  const item = localStorage.getItem(key);
  if (item === null) {
    return defaultValue;
  }

  return JSON.parse(item) as T;
}

export function setStorageItem<T>(key: string, value: T): T {
  if (isOnServer()) {
    return value;
  }

  localStorage.setItem(key, JSON.stringify(value));

  return value;
}
