import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useLocation } from '@reach/router';

import { useStore } from '../../../components/store';
import { API_URL } from '../../../environment';
import { localiseUrl } from '../../../utils/language';
import { useQuoteConfiguration } from '../hooks/useQuoteConfiguration';
import { BookingQuote, QuoteForm } from '../quote.types';
import commonStyles from './styles';

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    marginLeft: '36px',
  },
}));

const getQuoteId = (search: string) =>
  new URLSearchParams(search).get('quoteId');

const onNewQuoteClicked = () => navigate(localiseUrl('quote'));

const QuoteProcessing: React.FC = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const { setBookingQuote } = useStore((state) => state);
  const { updateQuoteConfiguration } = useQuoteConfiguration();

  const location = useLocation();
  const [quote, setQuote] = useState<BookingQuote | null>();

  const updateAndRedirectQuote = useCallback(
    (data) => {
      if (!data) return;

      setBookingQuote(data);
      updateQuoteConfiguration(data as QuoteForm);
      navigate(localiseUrl('quote-request-received'));
    },
    [setBookingQuote, updateQuoteConfiguration],
  );

  const getQuote = useCallback(async () => {
    const result = await fetch(
      `${API_URL}/bookings/${getQuoteId(location.search)}`,
      {
        method: 'GET',
      },
    );
    if (result.status === 200) {
      const data = await result.json();
      setQuote(data);

      !data?.isPaidOnline && updateAndRedirectQuote(data);
    } else setQuote(null);
  }, [location]);

  useEffect(() => {
    getQuote();
  }, []);

  const quoteNotFound = quote === null;

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          {quote?.isPaidOnline ? (
            <Box className={commonClasses.header}>
              <Typography variant="h3" gutterBottom>
                This quote is already paid.
              </Typography>
              <Grid container>
                <Typography variant="h5">To create new quote:</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onNewQuoteClicked}
                  className={classes.button}
                >
                  New Quote
                </Button>
              </Grid>
            </Box>
          ) : quoteNotFound ? (
            <Box className={commonClasses.header}>
              <Typography variant="h3" gutterBottom>
                This quote cannot be found
              </Typography>
              <Grid container>
                <Typography variant="h5">To create new quote :</Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={onNewQuoteClicked}
                  className={classes.button}
                >
                  New Quote
                </Button>
              </Grid>
            </Box>
          ) : (
            <Box className={commonClasses.header}>
              <Typography variant="h3" gutterBottom>
                We are redirecting you. Please wait.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuoteProcessing;
