/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';

import { Button } from '@material-ui/core';

import { Chat } from '../../../components/Chat';
import { useQuoteConfiguration } from '../hooks/useQuoteConfiguration';

export const QuoteChat: React.FC = () => {
  const { quoteConfiguration } = useQuoteConfiguration();
  const [showChat, setShowChat] = useState(false);

  if (
    quoteConfiguration?.quoteType !== 'domestic' ||
    !quoteConfiguration.allowChat ||
    !quoteConfiguration.chatOptions
  ) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setShowChat(true)}>
        Need help?
      </Button>
      <Chat
        url={`${quoteConfiguration.chatOptions.domestic}`}
        showChat={showChat}
      />
    </>
  );
};
