import React from 'react';

import { Button, Grid } from '@material-ui/core';

import { QuoteForm } from '../../../quote/quote.types';
import { useStyles } from './NewQuoteEnquiry.styles';

type NewQuoteEnquiryProps = {
  navigateToQuote: (quoteType: QuoteForm['quoteType']) => void;
};

const NewQuoteEnquiry: React.FC<NewQuoteEnquiryProps> = ({
  navigateToQuote,
}) => {
  const classes = useStyles();
  return (
    <Grid item>
      <Grid container className={classes.buttonsContainer}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigateToQuote('domestic')}
          >
            Quote Domestic
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigateToQuote('international')}
          >
            Quote International
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewQuoteEnquiry;
