import { navigate } from 'gatsby';
import React, { useMemo, useState } from 'react';
import { CalendlyEventListener } from 'react-calendly';

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { WhiteButton } from '../../../components/buttons';
import { useStore } from '../../../components/store';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { defaultLocale, localiseUrl } from '../../../utils/language';
import commonStyles from './styles';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 21,
  },
}));

const ScheduleCall: React.FC = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const backBookingSuccessful = useAnalytics();
  const localeCountryId = defaultLocale.slice(-2);

  const bookingQuote = useStore((state) => state.bookingQuote);
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  const onConfirm = () => {
    backBookingSuccessful({
      event: 'backBookingSuccessful',
    });

    const url = 'thankyou-booking';
    navigate(localiseUrl(url));
  };

  const chooseCalendlyLink = useMemo(() => {
    if (localeCountryId === 'GB')
      return 'https://calendly.com/petsabroad?embed_domain=tcampb.github.io&embed_type=Inline';

    if (bookingQuote?.options?.isBreeder)
      return 'https://calendly.com/jonathan-dogtainers?embed_domain=tcampb.github.io&embed_type=Inline';

    if (localeCountryId === 'AU') {
      if (bookingQuote?.quoteType === 'domestic')
        return 'https://calendly.com/dogtainers-domestic/phone-call';

      //export (pet leaving Australia)
      if (bookingQuote?.departure?.countryId === 'AU')
        return 'https://calendly.com/dogtainers-exports/phone-call';
    }

    return 'https://calendly.com/dogtainers-imports/phone-call?month=2023-03';
  }, [localeCountryId]);

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Typography variant={matches ? 'h4' : 'h3'} gutterBottom>
              Schedule your call with us
            </Typography>
            <Typography variant="h5">Let us help you out.</Typography>
          </Box>

          <Box mb={4}>
            <CalendlyEventListener
              onEventScheduled={function noRefCheck() {
                setConfirmDisabled(false);
              }}
            >
              <div
                style={{
                  height: '630px',
                  minWidth: '320px',
                }}
              >
                <iframe
                  frameBorder="0"
                  height="100%"
                  src={chooseCalendlyLink}
                  width="100%"
                />
              </div>
            </CalendlyEventListener>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection={!matches ? 'row' : 'column'}
          >
            <Box mr={!matches ? 4 : 0} mb={matches ? 2 : 0}>
              <WhiteButton
                variant="outlined"
                fullWidth
                onClick={() => navigate(localiseUrl('quote-request-received'))}
              >
                Cancel
              </WhiteButton>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={confirmDisabled}
            >
              Confirm
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ScheduleCall;
