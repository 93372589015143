import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  fileCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '30px',
  },
  fileNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  failedIcon: {
    color: 'red',
  },
  succeededIcon: {
    color: theme.palette.primary.main,
  },
  statusIconWrapper: {
    width: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiSvgIcon-root': {
      display: 'block',
    },
  },
}));

export default useStyles;
