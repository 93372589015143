import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  checkboxWrapper: {
    '& .MuiFormGroup-row': {
      marginTop: '10px',
      flexDirection: 'column',
    },
  },
}));

export { useStyles };
