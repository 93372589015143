import { gql } from '@apollo/client/core';

export const DOMESTIC_CONFIGURATION_FIELDS_FRAGMENT = `
  fragment DomesticConfigurationFields on DomesticQuoteConfig {
    averageDaysForFullQuote
    allowOnlinePayments
    allowBookingAppointments
  }
`;

export const INTERNATIONAL_CONFIGURATION_FIELDS_FRAGMENT = `
  fragment InternationalConfigurationFields on InternationalQuoteConfig {
    averageDaysForFullQuote
    allowBookingAppointments
  }
`;

export const GET_DOMESTIC_CONFIGURATION_QUERY = gql`
  ${DOMESTIC_CONFIGURATION_FIELDS_FRAGMENT}
  query DomesticConfiguration($franchiseCode: String!) {
    domestic: domesticQuoteConfigs {
      quoteConfiguration: getDomesticQuoteConfig(
        franchiseCode: $franchiseCode
      ) {
        ...DomesticConfigurationFields
      }
    }
  }
`;

export const GET_INTERNATIONAL_CONFIGURATION_QUERY = gql`
  ${INTERNATIONAL_CONFIGURATION_FIELDS_FRAGMENT}
  query InternationalConfiguration($franchiseCode: String!) {
    international: internationalQuoteConfigs {
      quoteConfiguration: getInternationalQuoteConfig(
        franchiseCode: $franchiseCode
      ) {
        ...InternationalConfigurationFields
      }
    }
  }
`;
